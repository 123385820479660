import { ObjectId } from '@freightview/object-id'

import {
  vArray,
  vEmail,
  vObject,
  vObjectId,
  vOutput,
  vPicklist,
  vString,
} from '../validation'
import { UserPermissionType, userPermissionTypes } from './common'
import { User } from './user'

export interface AccountVendor {
  _id: ObjectId
  created: Date
  name: string
  permissions: UserPermissionType[]
  removed?: boolean
  removedDate?: Date
  removedBy?: ObjectId
}

export interface AccountVendorUser extends User {
  accountUserId: ObjectId
}

export interface VendorWithUsers extends AccountVendor {
  users: AccountVendorUser[]
}

export const upsertVendorWithUsersSchema = vObject({
  name: vString(),
  permissions: vArray(vPicklist(userPermissionTypes)),
  users: vArray(vEmail),
})
export type UpsertVendorWithUsersRequest = vOutput<
  typeof upsertVendorWithUsersSchema
>

export interface VendorShipperAccountResponse {
  _id: ObjectId
  name: string
}

export const updateVendorCurrentShipperSchema = vObject({
  _id: vObjectId(),
})
export type UpdateVendorCurrentShipperRequest = vOutput<
  typeof updateVendorCurrentShipperSchema
>
