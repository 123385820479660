import {
  vArray,
  vCountryAny,
  vDate,
  vEmail,
  vNumber,
  vObject,
  vObjectId,
  vOptional,
  vOutput,
  vPhoneNumber,
  vPicklist,
  vPipe,
  vPostalCode,
  vString,
  vTransform,
} from '../validation'
import {
  LegacyShipType,
  legacyShipTypes,
  locationAccessorialSchema,
  ShipType,
  shipTypes,
} from './location-accessorial'
import { ShipTypeMapper } from './ship-type'

export const locationRefNumTypes = ['other'] as const
export const locationTypes = ['origin', 'destination', 'stop'] as const
export const stopTypes = ['pickup', 'delivery', 'both'] as const
export const stopDateTypes = ['on', 'by', 'between'] as const
export type LocationType = (typeof locationTypes)[number]
export type StopType = (typeof stopTypes)[number]
export type StopDateType = (typeof stopDateTypes)[number]
export type LocationRefNumType = (typeof locationRefNumTypes)[number]

export const loadLocationRefNumSchema = vObject({
  type: vPicklist(locationRefNumTypes),
  value: vString(),
})
const loadLocationShipTypeSchema = vPipe(
  vPicklist([...shipTypes, ...legacyShipTypes]),
  vTransform<LegacyShipType | ShipType, LegacyShipType>(v =>
    legacyShipTypes.includes(v as LegacyShipType)
      ? (v as LegacyShipType)
      : ShipTypeMapper.coreToLegacy(v as ShipType),
  ),
)
export const loadLocationSchema = vObject({
  addressBookId: vOptional(vObjectId()),
  accountLocationId: vOptional(vObjectId()),
  sequence: vNumber(),
  shipType: loadLocationShipTypeSchema,
  stopType: vPicklist(stopTypes),
  company: vOptional(vString()),
  address: vOptional(vString()),
  address2: vOptional(vString()),
  adminArea3: vOptional(vString()),
  city: vString(),
  state: vString(),
  postalCode: vPostalCode(),
  country: vCountryAny,
  // lat: vNumber(),
  // lng: vNumber(),
  timezone: vString(),
  stopDate: vOptional(vDate()),
  stopDateType: vPicklist(stopDateTypes),
  opensAt: vOptional(vString()),
  closesAt: vOptional(vString()),
  instructions: vOptional(vString()),
  accessorials: vOptional(vArray(locationAccessorialSchema)),
  contactName: vOptional(vString()),
  contactEmail: vOptional(vEmail),
  contactPhone: vOptional(vPhoneNumber()),
  contactPhoneExt: vOptional(vString()),
  type: vPicklist(locationTypes),
  refNums: vOptional(vArray(loadLocationRefNumSchema)),
})
export type LoadLocationDto = vOutput<typeof loadLocationSchema>
export type LocationRefNum = vOutput<typeof loadLocationRefNumSchema>
export type LoadLocation = Omit<LoadLocationDto, 'shipType'> & {
  shipType?: LegacyShipType
  lat: number
  lng: number
}
